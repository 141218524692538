import React from 'react';
import styled from '@emotion/styled';
import { graphql, PageProps } from 'gatsby';
import SEO from '@components/SEO';
import { PageContext, IPrismicBookADemo } from '@appTypes';

import FooterSection from '@pageSections/common/footer';
import CallToActionSection from '@pageSections/common/callToAction';
import HeaderSection from '@pageSections/bookADemoPage/header';
import TrustedBySection from '@pageSections/homePage/trusted-by';
import SuccessFormPhoto from '@assets/images/success-form.png';

const BookSuccessPage: React.FC<PageProps<IPrismicBookADemo, PageContext>> = ({
  data: { prismicData, prismicFooterData, prismicCtaData, prismicHomeData },
  pageContext,
  location,
}) => {
  const { locale } = pageContext;
  const data = prismicData!.edges[0].node.data;

  const { meta_title, meta_description, meta_image, meta_url } = data;

  data.book_a_demo_title = "Let's meet!";
  data.book_a_demo_subtitle = (
    <>
      Book a call with us using the <a href='https://calendly.com/hyperhumancc/meet-hyperhuman'>following link</a>.
    </>
  );

  const homeData = prismicHomeData?.edges[0].node.data;

  return (
    <>
      <Container style={{ marginTop: 34 }}>
        <SEO
          title={meta_title}
          description={meta_description}
          image={meta_image.url}
          url={meta_url}
          pathname={location.pathname}
          name='Hyperhuman'
          locale={locale}
        />
        <HeaderSection {...data} />
        <ImageWrapper>
          <img src={SuccessFormPhoto} />
        </ImageWrapper>
        {homeData && <TrustedBySection {...homeData} />}
      </Container>
      <CallToActionSection prismicCtaData={prismicCtaData} />
      <FooterSection prismicFooterData={prismicFooterData} subscribeForm />
    </>
  );
};

export default BookSuccessPage;

const ImageWrapper = styled.div`
  max-width: 367px;
  margin: 78px auto 120px auto;
`;

const Container = styled.div`
  position: initial;
`;

export const pageQuery = graphql`
  query BookSuccessQuery($locale: String!) {
    prismicData: allPrismicBookADemo(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          lang
          data {
            meta_title
            meta_keywords
            meta_description
            meta_image {
              url
            }
            meta_url
            book_a_demo_title
            book_a_demo_subtitle
          }
        }
      }
    }
    prismicHomeData: allPrismicHome(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          lang
          data {
            trusted_by_image {
              alt
              url
            }
            trusted_by_image_mobile {
              alt
              url
            }
            trusted_title {
              html
            }
          }
        }
      }
    }
    prismicFooterData: allPrismicIndex(filter: { lang: { eq: $locale } }) {
      ...FooterFieldsFragment
    }
    prismicCtaData: allPrismicIndex(filter: { lang: { eq: $locale } }) {
      ...CtaFieldsFragment
    }
  }
`;
